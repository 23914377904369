import { FormSelectInputBasic } from 'components/Forms';
import { SelectOption } from 'components/Forms/FormSelectInput';
import { constants } from 'variables';
import { useGetAllUsers } from 'views/pages/Admin/Users/hooks/useGetAllUsers';

interface UsersProps {
  defaultValue?: string | number | boolean | SelectOption;
  isLoading?: boolean;
  label?: string;
  onChange: (e: SelectOption) => void;
}

const Users: React.FC<UsersProps> = ({ defaultValue, label, isLoading, onChange }) => {
  const { usersDropdownOptions } = useGetAllUsers(`page=1&page_size=${constants.ALL_PAGES}`);

  return (
    <FormSelectInputBasic
      defaultValue={defaultValue}
      isLoading={isLoading}
      label={label || 'Users'}
      options={usersDropdownOptions}
      onChange={onChange}
      block
    />
  );
};

export default Users;
