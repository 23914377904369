import { FormSelectInputBasic } from 'components/Forms';
import { SelectOption } from 'components/Forms/FormSelectInput';
import { dropdownOptions } from 'variables';

interface VerificationStatusProps {
  defaultValue?: string | number | boolean | SelectOption;
  label?: string;
  onChange: (e: SelectOption) => void;
  isLoading?: boolean;
}

const VerificationStaus: React.FC<VerificationStatusProps> = ({ defaultValue, label, onChange, isLoading }) => {
  return (
    <FormSelectInputBasic
      defaultValue={defaultValue}
      label={label || 'Verification Staus'}
      options={dropdownOptions.VERIFICATION_STATUS}
      onChange={onChange}
      isLoading={isLoading}
      block
    />
  );
};

export default VerificationStaus;
