import { FormSelectInputBasic } from 'components/Forms';
import { SelectOption } from 'components/Forms/FormSelectInput';
import { useViewAllRoles } from 'views/pages/Admin/Roles/useViewAllRoles';

interface RolesProps {
  defaultValue?: string | boolean | SelectOption;
  isLoading?: boolean;
  label?: string;
  onChange: (e: SelectOption) => void;
}

const Roles: React.FC<RolesProps> = ({ defaultValue, label, isLoading, onChange }) => {
  const { rolesDropdownOptions } = useViewAllRoles('');

  return (
    <FormSelectInputBasic
      defaultValue={defaultValue}
      isLoading={isLoading}
      label={label || 'Roles'}
      options={rolesDropdownOptions}
      onChange={onChange}
      block
    />
  );
};

export default Roles;
