import { FilterProps } from 'components/Filters/types';
import { FormSelectInputBasic } from 'components/Forms';
import { CreateConfigTypes } from 'views/pages/Admin/Config/hooks/useCreateConfig';
import { useGetConfigs } from 'views/pages/Admin/Config/hooks/useGetConfigs';

interface ConfigFilterProps extends FilterProps {
  configType: CreateConfigTypes;
}
const ConfigFilter: React.FC<ConfigFilterProps> = ({ defaultValue, label, isLoading, onChange, configType }) => {
  const { dataDropdownOptionsIdValue } = useGetConfigs(configType);

  return (
    <FormSelectInputBasic
      defaultValue={defaultValue}
      isLoading={isLoading}
      label={label}
      options={dataDropdownOptionsIdValue}
      onChange={onChange}
      block
    />
  );
};

export default ConfigFilter;
