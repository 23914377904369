import { useState } from 'react';

import { helperUtils } from 'utils';
import { constants } from 'variables';
import { SelectOption } from 'components/Forms/FormSelectInput';

export interface FilterParams {
  page?: number;
  pageSize?: number;
  firstName?: SelectOption['value'];
  lastName?: SelectOption['value'];
  email?: SelectOption['value'];
  phoneNumber?: SelectOption['value'];
  accountStatus?: SelectOption['value'];
  assignedFamily?: SelectOption['value'];
  assignedZone?: SelectOption['value'];
  assignedMinistry?: SelectOption['value'];
  role?: SelectOption['value'];
}

export const useFilterParams = () => {
  const [params, setParams] = useState<FilterParams>({
    page: 1,
    pageSize: constants.PAGE_SIZE,
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    accountStatus: '',
    assignedFamily: '',
    assignedZone: '',
    assignedMinistry: '',
    role: '',
  });

  return {
    setParams: ({ ...data }: FilterParams) => setParams({ ...params, ...data }),
    params,
    paramsString: helperUtils.createFilterUrlString(params),
    pageSize: constants.PAGE_SIZE,
  };
};
