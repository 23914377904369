import Select, { MultiValue, SingleValue } from 'react-select';
import { Col, FormGroup } from 'reactstrap';

export type SelectValue = MultiValue<string | number> | SingleValue<string | number>;

export interface SelectOption {
  value?: string | number | boolean;
  label: string;
}
export interface FormSelectInputProps {
  isMulti?: boolean;
  isLoading?: boolean;
  options: SelectOption[];
  block?: boolean;
  placeholder?: string;
  label?: string;
  value?: string | number | boolean | SelectOption;
  defaultValue?: string | number | boolean | SelectOption;
  defaultInputValue?: string;
  isDisabled?: boolean;
  onChange: (e: SelectOption) => void;
}

const FormSelectInputBasic: React.FC<FormSelectInputProps> = ({
  isMulti,
  isLoading,
  isDisabled,
  options,
  block,
  placeholder,
  label,
  value,
  defaultInputValue,
  defaultValue,
  onChange,
}) => {
  console.log({ value });
  return (
    <Col md={block ? '12' : '6'}>
      <FormGroup>
        <label className="form-control-label text-capitalize">{label}</label>
        <Select
          value={value}
          // @ts-expect-error
          onChange={onChange}
          placeholder={placeholder ?? 'Select...'}
          isLoading={isLoading}
          isMulti={isMulti}
          options={options}
          defaultInputValue={defaultInputValue}
          defaultValue={defaultValue}
          isDisabled={isDisabled}
          styles={{
            input: (base) => ({
              ...base,
              height: 35,
              color: '#8898aa',
            }),
            option: (base) => ({
              ...base,
              backgroundColor: '#fff',
              color: '#000',
              ':hover': {
                backgroundColor: '#eee',
              },
            }),
          }}
        />
      </FormGroup>
    </Col>
  );
};

export default FormSelectInputBasic;
