import { FormSelectInputBasic } from 'components/Forms';
import { SelectOption } from 'components/Forms/FormSelectInput';
import { dropdownOptions } from 'variables';

interface FilterOptionsProps {
  defaultValue?: string | number | boolean | SelectOption;
  label: string;
  onChange: (e: SelectOption) => void;
  isLoading?: boolean;
  value?: string | number | boolean | SelectOption;
}
const FilterOptions: React.FC<FilterOptionsProps> = ({ defaultValue, label, isLoading, onChange, value }) => {
  return (
    <FormSelectInputBasic
      defaultValue={defaultValue}
      label={label}
      onChange={onChange}
      options={dropdownOptions.FILTER_OPTIONS}
      isLoading={isLoading}
      block
    />
  );
};

export default FilterOptions;
