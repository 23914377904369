import isEmpty from 'lodash.isempty';
import some from 'lodash.some';
import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';

import { FilterOptions, Roles, Search } from 'components/Filters';
import ConfigFilter from 'components/Filters/ConfigsFilter';
import VerificationStaus from 'components/Filters/VerifiationStatus';
import { FilterParams } from 'hooks/useFilterParams';
import { CreateConfigTypes } from 'views/pages/Admin/Config/hooks/useCreateConfig';

export enum FilterEnum {
  USERS = 'Users',
  ROLES = 'Roles',
  SEARCH_INPUT = 'Search',
  IS_SUPERUSER = 'is_Superuser',
  VERIFICATION_STATUS = 'Verification_status',
  CREATED_BY = 'Created_By',
}

const defaultFilters: FilterParams = {
  page: 1,
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  accountStatus: '',
  assignedFamily: '',
  assignedZone: '',
  assignedMinistry: '',
  role: '',
};

interface PageFiltersProps {
  filters: FilterEnum[];
  setParams?: (params: FilterParams) => void;
  setShowPageFilter?: (e?: boolean) => void;
}

export const PageFilters: React.FC<PageFiltersProps> = ({
  filters,
  setParams = () => {},
  setShowPageFilter = () => {},
}) => {
  const [filterState, setFilterState] = useState(defaultFilters);
  const [selectedFilter, setSelectedFilter] = useState({
    label: '',
    value: '',
    id: '',
  });

  const handleSubmit = () => {
    setParams({ ...filterState, page: 1 });
  };

  const pageFilterConfig: { [key: string]: React.ReactNode } = {
    role: <Roles label="Roles" onChange={(e) => setFilterState({ role: e.value })} />,
    assignedFamily: (
      <ConfigFilter
        configType={CreateConfigTypes.FAMILY}
        label="Select Family"
        onChange={(e) => setFilterState({ assignedFamily: e.value })}
      />
    ),
    assignedZone: (
      <ConfigFilter
        configType={CreateConfigTypes.ZONE}
        label="Select Zone"
        onChange={(e) => setFilterState({ assignedFamily: e.value })}
      />
    ),
    assignedMinistry: (
      <ConfigFilter
        configType={CreateConfigTypes.MINISTRY}
        label="Select Ministry"
        onChange={(e) => setFilterState({ assignedFamily: e.value })}
      />
    ),
    accountStatus: (
      <VerificationStaus label="Account Status" onChange={(e) => setFilterState({ accountStatus: e.value })} />
    ),
    firstName: <Search label="Type first name" onChange={(e) => setFilterState({ firstName: e.target.value })} />,
    lastName: <Search label="Type last name" onChange={(e) => setFilterState({ lastName: e.target.value })} />,
    email: <Search label="Type email" onChange={(e) => setFilterState({ email: e.target.value })} />,
    phoneNumber: <Search label="Type phone number" onChange={(e) => setFilterState({ phoneNumber: e.target.value })} />,
  };

  return (
    <>
      <Container fluid className="mt-3">
        <Row className="justify-content-center">
          <Card className="w-100 bg-translucent-neutral">
            <CardBody>
              <Row className="justify-content-center">
                <Col sm="12" md="4">
                  <FilterOptions
                    label="Select filter"
                    onChange={(e) => {
                      //  @ts-expect-error
                      setSelectedFilter(e);
                      setParams({ ...defaultFilters });
                      setFilterState(defaultFilters);
                    }}
                  />
                </Col>
                {selectedFilter.id && (
                  <Col sm="12" md="4">
                    {pageFilterConfig[selectedFilter.id]}
                  </Col>
                )}
              </Row>
              <Row>
                <Col className="text-center">
                  <Button
                    className="btn-sm"
                    color="success"
                    onClick={() => handleSubmit()}
                    disabled={some(filterState, isEmpty)}
                  >
                    Search <i className="fa fa-search" />
                  </Button>
                  <Button
                    className="btn-sm"
                    color="warning"
                    onClick={() => {
                      setParams({ ...defaultFilters });
                      setFilterState(defaultFilters);
                      setSelectedFilter({ label: '', value: '', id: '' });
                      // setShowPageFilter();
                    }}
                  >
                    Clear search <i className="fa fa-cancel" />
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Row>
      </Container>
    </>
  );
};

export default PageFilters;
