export enum FilterOptionsTypes {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  ACTIVATION_STATUS = 'accountStatus',
  FAMILY = 'assignedFamily',
  ZONE = 'assignedZone',
  MINISTRY = 'assignedMinistry',
  ROLE = 'role',
}

const dropdownOptions = {
  VERIFICATION_STATUS: [
    { label: 'Activated', value: 'activated' },
    { label: 'Deactivated', value: 'deactivated' },
    { label: 'Invited', value: 'invited' },
    { label: 'Pending', value: 'pending' },
  ],
  GENDER: [
    { label: 'Female', value: 'Female' },
    { label: 'Male', value: 'Male' },
  ],
  MARITAL_STATUS: [
    { label: 'Divorced', value: 'Divorced' },
    { label: 'Married', value: 'Married' },
    { label: 'Other', value: 'Other' },
    { label: 'Single', value: 'Single' },
  ],
  YES_AND_NO_STR: [
    { label: 'NO', value: 'false' },
    { label: 'YES', value: 'true' },
  ],
  YES_AND_NO: [
    { label: 'No', value: false },
    { label: 'Yes', value: true },
  ],
  OFFICE_OCCUPIED: [
    { label: 'DEACON', value: 'DEACON' },
    { label: 'ELDER', value: 'ELDER' },
    { label: 'PREACHER', value: 'PREACHER' },
  ],
  FILTER_OPTIONS: [
    {
      label: 'Activation Status',
      value: 'accountStatus',
      id: FilterOptionsTypes.ACTIVATION_STATUS,
      isRestricted: true,
    },
    { label: 'Email', value: 'email', id: FilterOptionsTypes.EMAIL },
    { label: 'Family', value: 'assignedFamily', id: FilterOptionsTypes.FAMILY },
    { label: 'First Name', value: 'firstName', id: FilterOptionsTypes.FIRST_NAME },
    { label: 'Last Name', value: 'lastName', id: FilterOptionsTypes.LAST_NAME },
    { label: 'Ministry', value: 'assignedMinistry', id: FilterOptionsTypes.MINISTRY },
    { label: 'Phone Number', value: 'phoneNumber', id: FilterOptionsTypes.PHONE_NUMBER },
    { label: 'Role', value: 'role', id: FilterOptionsTypes.ROLE, isRestricted: true },
    { label: 'Zone', value: 'assignedZone', id: FilterOptionsTypes.ZONE },
  ],
};

export default dropdownOptions;
